import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    // redirect: '/website',
    component: () => import('../views/website/index.vue'),
  },
  {
    path: '/trip',
    component: () => import('../views/H5/trip.vue'),
  },{
    path: '/website',
    component: () => import('../views/website/index.vue'),
  },{
    path: '/download',
    component: () => import('../views/website/download.vue'),
  },

]
const createRouter = () => new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_publicPath,
  scrollBehavior: () => ({ y: 0 }),
  routes //默认引入静态路由
})
const router = createRouter()
export default router
